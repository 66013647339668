import { PUBLIC_ENV } from '$env/static/public';
import { init } from '@jill64/sentry-sveltekit-cloudflare/client';

if (['Prod', 'Dev'].includes(PUBLIC_ENV || '')) {
	const onError = init(
		'https://9139ae04adbe65cb165ecea1d817b6d7@o4506636238651392.ingest.us.sentry.io/4507915769413632',
		{
			sentryOptions: {
				tracesSampleRate: 0.1,
				replaysSessionSampleRate: 0.1,
				replaysOnErrorSampleRate: 1.0,
				environment: PUBLIC_ENV
			}
		}
	);

	// export const handleError = onError((e, sentryEventId) => {
	// 	// Your Error Handler
	// });
}
