import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [0,9,3,4,5,6,8];

export const dictionary = {
		"/(main)": [10,[2]],
		"/adm": [40,[9]],
		"/adm/pictures": [41,[9]],
		"/adm/pictures/[pic_id]": [~42,[9]],
		"/(main)/characters": [11,[2]],
		"/(main)/characters/[character_id]": [~12,[2,3]],
		"/(main)/characters/[character_id]/drawings": [13,[2,3]],
		"/(main)/characters/[character_id]/fursuits": [14,[2,3]],
		"/(main)/characters/[character_id]/photos": [15,[2,3]],
		"/(main)/drawings": [16,[2]],
		"/(main)/fursuits": [17,[2]],
		"/(main)/fursuits/create": [20,[2]],
		"/(main)/fursuits/[fursuit_id]": [~18,[2,4]],
		"/(main)/fursuits/[fursuit_id]/photos": [19,[2,4]],
		"/(main)/makers": [21,[2]],
		"/(main)/makers/[maker_id]": [~22,[2,5]],
		"/(main)/makers/[maker_id]/fursuits": [23,[2,5]],
		"/(main)/notifications": [24,[2]],
		"/(main)/oauth/callback": [25,[2]],
		"/(main)/oauth/success": [26,[2]],
		"/(main)/photos": [27,[2]],
		"/(main)/pictures/[pic_id]": [~28,[2,6]],
		"/(main)/pictures/[pic_id]/edit": [29,[2,6]],
		"/(main)/post": [30,[2]],
		"/(main)/settings": [~31,[2,7]],
		"/(main)/settings/layout": [32,[2,7]],
		"/(main)/settings/theme": [33,[2,7]],
		"/(main)/users": [34,[2]],
		"/(main)/users/[user_id]": [~35,[2,8]],
		"/(main)/users/[user_id]/characters": [36,[2,8]],
		"/(main)/users/[user_id]/drawings": [37,[2,8]],
		"/(main)/users/[user_id]/liked": [38,[2,8]],
		"/(main)/users/[user_id]/photos": [39,[2,8]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';